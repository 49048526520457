import React, { useEffect, useContext, useState, useRef } from "react";
import "./panel-chats.scss";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Text, Icon, Button } from "@ui-kit";
import { LoadingAdmin } from "@shared/ui";
import AdminProjectInfo from "../controlPanelAdmin/adminProjectInfo";
import {
  Typing,
  AdminInfoMenu,
  UserInfoMenu,
  ShortInfo,
  BriefInfoComponent,
  UserProjectInfo,
  FaqInfoComponent,
  ModalProject,
  DeleteProjectPopup,
} from "@widgets";
import {
  isAuth,
  isOnline,
  getCustomer,
  getMessages,
  setAlertAC,
  getProjectsLength,
  getCurrentProject,
  setChatMessageAC,
  setChatMessagesAC,
  setActivatedEmailShowAC,
  removeChatFilesTC,
  setCurrentProjectAC,
  setProjectPropertieInListAC,
  messageInstance,
  saveUserProjectTC,
  uploadChatFilesTC,
  removeNotificationTC,
  getProjectsIsLoading,
  updateAdminProjectPropertyTC,
  Bot,
  Snackbar,
  setIsUnsavedProjectShowAC,
  getProductToProject,
  setProductToProjectAC,
  getDeviceType,
  getProjectsSearchValue,
  getProjectWasChanged,
  setisProjectChangeAC,
  setCurrentProjectImageAC,
  getAdminProjectTC,
} from "@entities";
import { ROLES } from "@entities/user";
import { CHAT_EVENTS } from "./events.constants";
import {
  Message,
  FileMessage,
  ProductMessage,
  EmptyProject,
  GreetingsMessage,
  NotFoundMessage,
} from "@features";
import { ProjectsContext } from "@shared/hooks";
import { deepObjectCopy } from "@shared";
import { replaceTo } from "@lib/utils";
import BackIcon from "@ui-kit/icons/chevron-accent700.svg";
const { REACT_APP_PROJECTS_URL } = process.env;

const PanelChats = ({
  className = "",
  isDisabledSend = false,
  setUserActiveInfo = () => {},
  role,
  setIsChatView,
}) => {
  const chatBody = useRef();
  const chatTyping = useRef();
  const dispatch = useDispatch();
  const projectsContext = useContext(ProjectsContext);

  const user = useSelector(getCustomer);
  const projectIsNotSaved = useSelector(getProjectWasChanged);
  const messages = useSelector(getMessages);
  const isUserOnline = useSelector(isOnline);
  const projectsIsLoading = useSelector(getProjectsIsLoading);
  const projectsLength = useSelector(getProjectsLength);
  const currentProject = useSelector(getCurrentProject);
  const productToProject = useSelector(getProductToProject);
  const deviceType = useSelector(getDeviceType);
  const projectsSearchValue = useSelector(getProjectsSearchValue);
  const isUserAuth = useSelector(isAuth);

  const [formValue, setFormValue] = useState("");
  const [filesValue, setFilesValue] = useState([]);
  const [socketIsInit, setSocketIsInit] = useState(false);
  const [activeUserPanel, setActivePanel] = useState("hidden");
  const [forceRender, setForceRender] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isArchive,
    activePanel,
    isDeleteProjectShow,
    setActiveInfo,
    setDeleteProjectShow,
    projects,
  } = projectsContext;

  const {
    id,
    dir,
    title,
    roomId,
    reason_for_deletion,
    notificationsToUser,
    renderImageUrl,
    sketchImage,
    renderImage,
  } = currentProject;

  let isNewProject = false;
  const isUserRole = user.role === ROLES.USER;
  const isUser = role === ROLES.USER ? true : false;
  const isShowNotification = notificationsToUser?.length > 0;
  const isListEmpty = !projectsIsLoading && !projectsLength;
  const isChatDisabled =
    !!reason_for_deletion || isListEmpty || isDisabledSend || !id;
  const isGreetingsMsg = isUser && isListEmpty;
  const isFake = typeof id !== "number" && id?.includes("-");
  const isFilterValues =
    !!searchParams.get("state") || !!searchParams.get("status");
  const isCurrentInList = projects.filter(
    (project) => project.id === currentProject?.id
  );
  const isDisableInfoMenu =
    !currentProject?.id || !messages.length || !isCurrentInList.length;

  useEffect(() => {
    let timeoutId;

    const setTimeoutTab = () => {
      timeoutId = setTimeout(() => {
        window.socket.close();
      }, 60000)
    }

    const visibilityTabChange = () => {
      const isConnected = window.socket.isConnected()
      if (document.hidden) {
        // tab not active
        //console.log('Tab hidden. Socket connected', isConnected);
        return setTimeoutTab();
      } 
      
      // tab active
      //console.log('Tab active. Socket connected', isConnected);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if(!isConnected) {
        window.socket.reconnect();
        //window.location.reload()
      }
    }

    document.addEventListener('visibilitychange', visibilityTabChange);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      document.removeEventListener('visibilitychange', visibilityTabChange);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [projectsIsLoading]);

  useEffect(() => {
    setTimeout(() => updateScroll(), 100);
    const editor = document.querySelector(".editor");

    return () => {
      setFilesValue([]);

      if (editor?.textContent) {
        editor.textContent = "";
      }
    };
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!isUserOnline) {
      return;
    }

    setFormValue("");

    const currentRoomId = window.roomId;

    if (currentRoomId === roomId && socketIsInit) {
      return () => {};
    }

    if (!roomId && !isFake) {
      console.info(`[ROOMID]- NOT`);
      dispatch(setChatMessagesAC([]));
      return () => {};
    }

    window.roomId = roomId;

    initSocket(roomId);

    return () => {
      if (window.socket.connected) {
        window.socket.send(CHAT_EVENTS.ROOM_LEAVE, {});
      } 
    };
    // eslint-disable-next-line
  }, [id, isUserOnline]);

  useEffect(() => {
    if (
      !isUser &&
      (projectsSearchValue || isFilterValues) &&
      (!projects.length || !isCurrentInList.length)
    ) {
      searchParams.delete("project_asset_id");
      setSearchParams(searchParams);
      dispatch(setCurrentProjectAC({}));
      dispatch(setChatMessagesAC([]));
    }
    // eslint-disable-next-line
  }, [projects.length]);

  const renderChat = () => {
    if ((projectsSearchValue || isFilterValues) && !projects.length) {
      return <NotFoundMessage value={projectsSearchValue} />;
    }

    if (projects.length && isDisableInfoMenu) {
      return (
        <div className="chat__empty">
          <Text as="h6" className="empty-label">
            Select a project to work with
          </Text>
        </div>
      );
    }

    return (
      <div className={classesBody.join(" ")} ref={chatBody}>
        {renderMessages()}
      </div>
    );
  };

  const renderMessages = () => {
    if (isGreetingsMsg) {
      return <GreetingsMessage />;
    }

    return (
      <div>
        {messages.map((message, index) => (
          <Message
            key={`message-${index}`}
            message={message}
            ownerId={user.id}
            isUser={isUser}
          />
        ))}
      </div>
    );
  };

  // decline empty render
  if (!user || !isUserOnline) {
    return;
  }

  const classes = [
    "chats__panel",
    "d-flex",
    "fd-column",
    ...className.split(" "),
  ];

  const classesBody = ["chat__body-main"];

  if (isShowNotification) {
    classesBody.push("notification__padding");
  }

  if (filesValue.length) {
    classesBody.push("file-uploading__padding");
  }

  if (!isUser) {
    classesBody.push("chat__admin");
  }

  function updateScroll() {
    const chatBodyElement = chatBody?.current;

    if (!chatBodyElement) return;

    if (isGreetingsMsg) {
      return (chatBodyElement.scrollTop = -163);
    }

    chatBodyElement.scrollTop = chatBodyElement?.scrollHeight;
  }

  const setExecutorContent = (message) => {
    if (!message?.type) {
      return;
    }

    let executorContent = {
      type: message.type,
      creator: { firstName: message.creatorId.firstName },
    };

    const stringLength = 12;

    if (message.type === "text") {
      executorContent["text"] = message.text.slice(0, stringLength);

      if (executorContent["text"].length >= 12) {
        executorContent["text"] = executorContent["text"] + "...";
      }
    }

    if (message.type === "img") {
      // const splitedAttchments = JSON.parse(message.attachments);
      const splitedAttchments = message.attachments;
      executorContent["text"] = splitedAttchments[splitedAttchments.length - 1];

      if (executorContent["text"].length >= 12) {
        executorContent["text"] = executorContent["text"] + "...";
      }
    }

    dispatch(
      setProjectPropertieInListAC({
        id,
        propertie: { key: "executorContent", value: executorContent },
      })
    );

    dispatch(
      updateAdminProjectPropertyTC({
        propagate: false,
        name: "executorContent",
        value: JSON.stringify(executorContent),
      })
    );
  };

  function initSocket(roomId) {
    setSocketIsInit(true);
    const socket = window.socket;

    socket.send(CHAT_EVENTS.ROOM_JOIN, { roomId });

    socket.listen((event) => {
      const data = event?.data;
      if (!data) {
        return;
      }

      const toJson = JSON.parse(data);
      const customEvent = toJson.event;

      switch (customEvent) {
        case CHAT_EVENTS.MESSAGES_SEND:
          const rows = toJson?.data?.rows || [];
          const messages = rows.map((message) =>
            messageInstance.create(message, message.type)
          );

          const bot = new Bot(null, currentProject);
          const botMessages = bot.getAllMessages();

          const messagesWithBotsMessages = [...botMessages, ...messages];

          dispatch(setChatMessagesAC(messagesWithBotsMessages));
          setExecutorContent(toJson.data.rows[toJson.data.rows.length - 1]);
          break;

        case CHAT_EVENTS.MESSAGE_SEND:
          dispatch(setChatMessageAC(toJson.data));
          setExecutorContent(toJson.data);
          break;

        //       case 'message.delete':
        //         // action.deleteMessageFromChatAC(toJson.data);
        //         break;

        //       case 'message.edit': {
        //         /* action.editMessageInChatAC(toJson.data);

        //           setIsEditingMessage({
        //               ...toJson.data,
        //               state: false,
        //               id: undefined,
        //               roomId: undefined,
        //           }); */

        //         break;
        //       }

        //       case 'chat.action':
        //       /* action.changeCurrentProjectFieldAC(
        //               toJson.data.isChatValue.toString(),
        //               'isChat'
        //           ); */

        default:
          return;
      }
    });
  }

  const validateMessage = (isMessageEmpty, isMessageLarge) => {
    if (isMessageEmpty) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">The message cannot be empty.</h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));
      return false;
    }

    if (isMessageLarge) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">The message entered is too large.</h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));
      return false;
    }

    const editorInput = document.getElementsByClassName("editor")[0];
    editorInput.style.backgroundColor = "transparent";

    return true;
  };

  const formatLinksMessage = (text) => {
    // eslint-disable-next-line
    const regex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(?![^<]*<\/a>)/gim;
    const simbolsMap = {
      "&quot;": '"',
      "&apos;": "'",
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&#039;": "'",
      "&nbsp;": " ",
    };
    let formatText = text.replace(/&([a-z0-9]+);/gim, function (m) {
      return simbolsMap[m];
    });
    formatText = formatText.replace(
      regex,
      '<a href="$1" target="_blank" rel="noreferrer">$1</a>'
    );
    formatText = replaceTo(formatText, "\\n", "<br>");

    return formatText;
  };

  const onSubmitMessage = async (textMessage) => {
    const isTextMessageIsString = typeof textMessage === "string";
    //if this is empty project
    if (!roomId) {
      return createProjectAndSubmitMessage();
    }

    //if this is real project
    const messageBase = {
      projectId: id,
      roomId: roomId,
      creatorId: user,
      fromId: user.id,
    };

    if (isUser) {
      messageBase["toId"] = currentProject?.interviewerId;
    } else {
      messageBase["toId"] = currentProject.userId;
    }

    /* if Files presented - send files at first */

    if (isTextMessageIsString) {
      return sendPreparedMessage(messageBase, textMessage);
    }

    return sendPreparedMessage(messageBase);
  };

  const createProjectAndSubmitMessage = async () => {
    const isActivatedEmail = +user.isActivated;
    if (!isActivatedEmail) {
      return dispatch(setActivatedEmailShowAC(true));
    }

    if (user.role === "ADMIN") return;

    isNewProject = true;

    const clearMessage = formatLinksMessage(formValue);
    const trimMessage = clearMessage.replaceAll("<br>", " ");
    const isMessageEmpty = trimMessage.trim() === "";

    // return if message is empty
    if (isMessageEmpty) {
      return validateMessage(isMessageEmpty, false);
    }

    currentProject["html_description"] = clearMessage.trim() || " ";
    currentProject["user"] = user;
    const executorText = productToProject?.title
      ? "Edit this illustration"
      : " ";
    currentProject["executorContent"] = {
      text: executorText,
      type: "text",
      creator: { firstName: user.firstName },
    };

    const newProject = await dispatch(saveUserProjectTC(currentProject));

    const messageBase = {
      projectId: newProject.id,
      roomId: newProject.roomId,
      creatorId: user,
      fromId: newProject.userId,
      toid: newProject?.interviewerId,
      dir: newProject.dir,
    };

    const result = dispatch(setCurrentProjectAC(newProject));

    /* !!! Create a project before sending prepared messages with files */
    /* if Files presented - send files at first */
    sendPreparedMessage(messageBase);
    updateScroll();
    setUserActiveInfo("info");

    searchParams.set("project_asset_id", newProject.id);
    setSearchParams(searchParams, { replace: true });

    dispatch(setProductToProjectAC({}));
    isNewProject = false;

    return result;
  };

  const sendPreparedMessage = (() => {
    let isSendingMessage = false;

    return async (messageBase, outSideMessage) => {
      if (isSendingMessage) return;

      isSendingMessage = true;

      let isMessageCanSended;
      const maxMessageLength =
        +process.env.REACT_APP_PROJECT_MAX_MESSAGE_LENGTH;
      const isFileMessagePresent = !!filesValue.length;
      const isMessageLarge = formValue.length > maxMessageLength;

      if (!outSideMessage) {
        if (isFileMessagePresent) {
          const imageMessage = await createImageMessage(messageBase);

          window.socket.send(CHAT_EVENTS.MESSAGE_SEND, imageMessage);

          setFilesValue([]);
        }

        setTimeout(() => onTypingHeightChange(), []);

        let clearMessage = formValue.trim();
        clearMessage = formatLinksMessage(clearMessage);

        const trimMessage = clearMessage.replaceAll("<br>", " ");
        const isMessageEmpty = trimMessage.trim() === "";

        /* return if files valid and message is empty */
        if (isMessageEmpty && isFileMessagePresent) {
          return false;
        }

        if (productToProject?.id && isNewProject) {
          clearMessage = ProductMessage(productToProject) + clearMessage;
        }

        /* validation */
        isMessageCanSended = validateMessage(isMessageEmpty, isMessageLarge);
        messageBase["text"] = clearMessage;
      } else {
        messageBase["text"] = outSideMessage;
        isMessageCanSended = true;
      }

      let message = messageInstance.create(messageBase);

      if (isMessageCanSended) {
        window.socket.send(CHAT_EVENTS.MESSAGE_SEND, message);

        const editor = document.querySelector(".editor");
        editor.innerHTML = "";

        setFormValue("");
        updateScroll();
      }

      isSendingMessage = false;
    };
  })();

  const createImageMessage = (message) => {
    return new Promise(async (resolve, reject) => {
      const uploadList = await Promise.all(
        filesValue.map(async (file) => {
          if (!file.isUploaded)
            file.uniqueName = await dispatch(uploadChatFilesTC(file.file));

          file.isUploaded = !!file.uniqueName;

          return file;
        })
      );
      const attachments = uploadList.map((item) => item.uniqueName);
      const copyMessage = deepObjectCopy(message);

      delete copyMessage.id;
      // copyMessage['attachments'] = JSON.stringify(attachments);
      copyMessage["attachments"] = attachments;

      const fileMessage = messageInstance.create(copyMessage, "img");

      resolve(fileMessage);
    });
  };

  const removeNotification = () => {
    dispatch(removeNotificationTC(id));
  };

  const renderNotification = () => {
    const notification = notificationsToUser[0];

    const copy = deepObjectCopy(notification);

    return (
      <ModalProject
        notification={copy}
        isUserRole={isUserRole}
        removeNotification={removeNotification}
        projectId={id}
      />
    );
  };

  const uploadFiles = async (files) => {
    const multipleLimit = 6;
    const filesArray = Array.from(files);

    if (filesValue.length + filesArray.length > multipleLimit) {
      return showError({
        uploadImage: `The maximum number of uploaded images in one message is ${multipleLimit}`,
      });
    }

    setFilesValue((files) => [...files, { uniqueName: "loader" }]);
    setTimeout(() => onTypingHeightChange(), 0);

    for (let file of filesArray) {
      let uniqueName;

      if (window.roomId) {
        uniqueName = await dispatch(uploadChatFilesTC(file));
        file.isUploaded = !!uniqueName;
      } else {
        uniqueName = file.name;
      }

      setFilesValue((files) => [
        ...files.filter((item) => item.uniqueName !== "loader"),
        { uniqueName, file },
        { uniqueName: "loader" },
      ]);
    }

    setFilesValue((files) => [
      ...files.filter((item) => item.uniqueName !== "loader"),
    ]);
  };

  const removeFileMessage = async (file) => {
    const uniqueName = file.uniqueName;

    const files = filesValue.filter((item) => item.uniqueName !== uniqueName);
    setFilesValue(files);
    setTimeout(() => onTypingHeightChange(), 0);
    await dispatch(removeChatFilesTC(uniqueName));
  };

  const showError = ({ uploadImage }) => {
    const snackbar = new Snackbar({
      status: 500,
      message: (
        <div className="">
          <h5 className="snack__header">{uploadImage}</h5>
        </div>
      ),
    });

    dispatch(setAlertAC(snackbar));
  };

  const onTypingHeightChange = () => {
    const chatBodyElement = chatBody?.current;
    const chatTypingElement = chatTyping?.current;

    if (!chatBodyElement || !chatTypingElement) return;
  };

  const definedChatHeaderStyles = () => {
    const classes = ["chats__header", "d-flex"];

    if (reason_for_deletion) {
      classes.push("deleted");
    }

    if (isUserRole) {
      classes.push("chats__header-user");
    }

    return classes.join(" ");
  };

  const loginClass = !isUserAuth ? "login" : "";
  const userStyles = ["user-info-tablet__wrapper", loginClass];

  const setActiveUserInfo = (panel) => {
    setActivePanel(panel);

    searchParams.set("user_info_active", panel);
    setSearchParams(searchParams, { replace: true });
  };

  const activeUserPanelsSet = {
    hidden: <></>,
    brief: <BriefInfoComponent />,
    info: <UserProjectInfo setActiveUserInfo={setActiveUserInfo} />,
    faq: <FaqInfoComponent />,
  };

  const onBackgroundClick = (e) => {
    if (e.target.id === "active-panel-background") {
      if (isUser) {
        return setActiveUserInfo("hidden");
      }

      setActiveInfo("chat");
    }
  };

  const renderActiveInfoPanel = () => {
    if (isUser) {
      return (
        <div className={userStyles.join(" ")}>
          <UserInfoMenu
            setActive={setActiveUserInfo}
            isActive={activeUserPanel}
            isDisabled={isDisableInfoMenu}
          />
          {activeUserPanel !== "hidden" && (
            <div
              id="active-panel-background"
              className="background-default"
              onMouseDown={onBackgroundClick}
            >
              <div className="panel-info__wrapper">
                {activeUserPanelsSet[activeUserPanel]}
              </div>
            </div>
          )}
        </div>
      );
    }

    if (isDisableInfoMenu) return;

    let concatPath;
    if (sketchImage || renderImage) {
      concatPath = `${dir}/${renderImage ? renderImage : sketchImage}`;
    }

    return (
      <>
        <AdminInfoMenu setActive={setActiveInfo} activeInfo={activePanel} />
        {activePanel !== "chat" && (
          <div className="admin-info-tablet__wrapper">
            <div
              id="active-panel-background"
              className="background-default"
              onMouseDown={onBackgroundClick}
            >
              <div className="admin-info__wrapper">
                <AdminProjectInfo
                  pathTo={concatPath}
                  currentProject={currentProject}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderMobileActiveInfoPanel = () => {
    if (isDisableInfoMenu) return;

    return (
      <div className="admin__mobile-info-menu">
        <AdminInfoMenu setActive={setActiveInfo} activeInfo={activePanel} />
      </div>
    );
  };

  const renderPreview = () => {
    if (sketchImage && !renderImageUrl) {
      return (
        <Icon
          src={`${REACT_APP_PROJECTS_URL}/${dir}/${sketchImage}`}
          className="user-project__preview-img"
        />
      );
    }

    if (!renderImageUrl) {
      return <EmptyProject />;
    }

    if (renderImageUrl) {
      return (
        <Icon src={renderImageUrl} className="user-project__preview-img" />
      );
    }
  };

  const backInProjects = () => {
    if (projectIsNotSaved && !isArchive) {
      return dispatch(setIsUnsavedProjectShowAC(true));
    }

    if (isArchive) {
      dispatch(getAdminProjectTC(currentProject.id));
      dispatch(setCurrentProjectImageAC(null));
      dispatch(setisProjectChangeAC(false));
    }

    if (isUser) {
      setActiveUserInfo("hidden");
    }

    searchParams.delete("project_asset_id");
    setSearchParams(searchParams, { replace: true });

    setIsChatView(false);
  };

  return (
    <div className={classes.join(" ")}>
      {!!isDeleteProjectShow && (
        <DeleteProjectPopup
          projectId={id}
          onSubmitMessage={onSubmitMessage}
          setUserActiveInfo={setUserActiveInfo}
          setDeleteProjectShow={setDeleteProjectShow}
        />
      )}

      <div className={definedChatHeaderStyles()}>
        <div className="chats__mobile-header-title">
          <div className="chats__mobile-header-info">
            <Button
              size="md"
              theme="tertiary-gray"
              iconLeft={BackIcon}
              onClick={backInProjects}
            />
            <div className="user-project__wrapper">{renderPreview()}</div>
            <Text as="h6">{title || ""}</Text>
          </div>
          {!isUser && renderMobileActiveInfoPanel()}
        </div>

        <Text as="h4" className="chats__header-title">
          {title || ""}
        </Text>

        {!isUser && (
          <ShortInfo
            project={currentProject}
            removeNotification={removeNotification}
          />
        )}

        {renderActiveInfoPanel()}

        <Text as="h4" className="chats__tablet-header-title">
          {title || ""}
        </Text>

        {!!isShowNotification && isUser && renderNotification()}
      </div>

      <div className="chat__body d-flex fd-column">
        {forceRender ? <LoadingAdmin /> : renderChat()}
      </div>

      <div className="chat__typing" ref={chatTyping}>
        {!!filesValue.length && (
          <div className="file-messages__container">
            {filesValue.map((file, index) => (
              <FileMessage
                file={file}
                key={`typing-file-${index}`}
                removeFileMessage={removeFileMessage}
              />
            ))}
          </div>
        )}

        <Typing
          formValue={formValue}
          showError={showError}
          uploadFiles={uploadFiles}
          onSubmit={onSubmitMessage}
          setFormValue={setFormValue}
          isMobile={deviceType.mobile}
          isDisabled={isChatDisabled}
          onTypingHeightChange={onTypingHeightChange}
        />
      </div>
    </div>
  );
};

export default PanelChats;
