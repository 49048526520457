export const PRODUCTS_TAGS = [
    {title: 'Cartoon', value: 'cartoon'},
    {title: 'Isometric', value: 'isometric'},
    {title: 'Flat', value: 'flat'},
    {title: 'Realistic', value: 'realistic'},
    {title: 'Doodle', value: 'doodle'},
    {title: 'Y2K', value: 'y2k'},
    {title: 'Groovy', value: 'groovy'},
    {title: 'Background', value: 'background'},
    {title: 'Parallax', value: 'parallax'},
    {title: 'Animation', value: 'animation'},
    {title: 'Icon', value: 'icon'},
    {title: 'UI', value: 'ui'},
    {title: '3D', value: '3d'},
    {title: 'Mockup', value: 'mockup'},
    {title: 'Character', value: 'character'},
    {title: 'Book', value: 'book'},
    {title: 'Fluorescent', value: 'fluorescent'},
    {title: 'Cosmic', value: 'cosmic'},
    {title: 'Girl', value: 'girl'},
    {title: 'Boy', value: 'boy'},
    {title: 'Animal', value: 'animal'},
    {title: 'Science', value: 'science'},
    {title: 'Marketing', value: 'marketing'},
    {title: 'Social', value: 'social'},
    {title: 'Beach', value: 'beach'},
    {title: 'Magic', value: 'magic'},
    {title: 'Set', value: 'set'},
]