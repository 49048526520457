import React, { useCallback, useRef, useEffect, useState } from 'react';
import './home_gallery.scss';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Icon, CarouselArrow, Badge } from '@ui-kit';
import { BROWSE_URL } from '@app/routes';
import { HoverProductCard } from '@widgets';
import {
  getCart,
  getAllAmount,
  getFreeAmount,
  addProductToCartTC,
  getProductsAmountTC,
  setModalGetFreeProductTC,
  SET_ENTIRE_AMOUNT,
  getHomeGallery,
  getIsLoading,
} from '@entities';

const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;

const Gallery = () => {
  const dispatch = useDispatch();
  const sliderElementFirst = useRef();
  const sliderElementSecond = useRef();
  const sliderTopRef = useRef();
  const sliderBottomRef = useRef();

  const totalAmount = useSelector(getAllAmount);
  const freeAmount = useSelector(getFreeAmount);
  const cart = useSelector(getCart);
  const productsIsLoading = useSelector(getIsLoading);
  const GALLERY_SLIDERS = useSelector(getHomeGallery);

  const MOBILE_WIDTH = 606;
  const [forceRender, setForceRender] = useState(true);
  const [viewItems, setViewItems] = useState();
  const [hideElements, setHideElements] = useState({top:{}, bottom:{}});
  const viewItemsRef = useRef(viewItems);

  useEffect(() => {
    if(productsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [productsIsLoading]);

  const filteredSimilars = () => {
    const currentViewItems = viewItemsRef.current;

    if (!currentViewItems || !currentViewItems.top || !currentViewItems.bottom) {
        return;
    }

    filterItems(currentViewItems);
  };

  useEffect(() => {
    renderItems()
    // eslint-disable-next-line
  }, [viewItems?.top?.length, viewItems?.bottom?.length]);

  useEffect(() => {
    setViewItems({...GALLERY_SLIDERS});
    viewItemsRef.current = { ...GALLERY_SLIDERS };

    if(sliderTopRef?.current) {
      filterItems(viewItems)
    };
    // eslint-disable-next-line
  }, [forceRender, GALLERY_SLIDERS?.top?.length]);

  useEffect(() => {
    dispatch(getProductsAmountTC(null, SET_ENTIRE_AMOUNT));
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      filteredSimilars();
  };

  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  let settingsTop = {
    infinite: true,
    speed: 700,
    centerMode: true,
    variableWidth: true,
    arrows: false,
    slidesToScroll: -1,
  };

  const settingsBottom = {
    ...settingsTop,
  };

  const getFree = (event, slide) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(setModalGetFreeProductTC({show: true, product: slide}));
  }

  const addToCart = useCallback((event, product) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(addProductToCartTC(product));
    // eslint-disable-next-line
  }, []);

  const defineItemInCart = (pathToBrowse) => {
    const product = cart.find((item) => item?.pathToBrowse === pathToBrowse);

    if (product?.pathToBrowse) {
      return true;
    }

    return false;
  };

  const navigateTo = useCallback((slide) => {
    // const href = `${BROWSE_URL.pathname}/${slide.linkId}-${slide.id}`;
    const href = `${BROWSE_URL.pathname}/${slide.linkId}`;
    window.open(href, '_blank');
  }, []);

  const filterItems = (items) => {
    if (!sliderTopRef?.current || !items?.top?.length) return;

    const windowWidth = document.body.clientWidth || 0;
    const sliderWidth = sliderTopRef?.current?.clientWidth || 0;
    //const sliderWidth = sliderTopRef?.current?.getBoundingClientRect().width || 0;
    const sliderItemsTop = Array.from(sliderTopRef?.current?.querySelectorAll('.slide__item'));
    const sliderItemsBottom = Array.from(sliderBottomRef?.current?.querySelectorAll('.slide__item'));
    const hideElementsTop = hideElements?.top;
    const hideElementsBottom = hideElements?.bottom;

    if (!sliderWidth || !windowWidth || windowWidth < MOBILE_WIDTH) {
      for (const key in hideElementsTop) {
        delete hideElementsTop[key];
      }

      for (const key in hideElementsBottom) {
        delete hideElementsBottom[key];
      }

      setHideElements({ top: hideElementsTop, bottom: hideElementsBottom });
      setViewItems(viewItemsRef?.current);
      return;
    }

    /* SLIDER TOP */
    let filteredItemsTop = [...items?.top];
    sliderItemsTop.map((sliderItem) => {
      const hideWidth = hideElementsTop[sliderItem.id]?.width;

      if(sliderItem?.clientWidth > sliderWidth && hideWidth === undefined) {
        hideElementsTop[sliderItem.id] = {width: sliderItem.clientWidth};
        filteredItemsTop = filteredItemsTop.filter((item) => +item.id !== +sliderItem.id)
      }

      return sliderItem;
    })

    Object.keys(hideElementsTop).map((id) => {
      if(hideElementsTop[id]?.width > sliderWidth) {
        hideElementsTop[id] = {width: hideElementsTop[id]?.width};
        filteredItemsTop = filteredItemsTop.filter((item) => +item?.id !== +id)
        return id;
      }

      if(hideElementsTop[id]?.width < sliderWidth) {
        const unhideItem = GALLERY_SLIDERS?.top.filter((item) => +item?.id === +id)[0];
        filteredItemsTop.push(unhideItem);

        delete hideElementsTop[id];
      }

      return id;
    })

    /* SLIDER BOTTOM */
    let filteredItemsBottom = [...items?.bottom];
    sliderItemsBottom.map((sliderItem) => {
      const hideWidth = hideElementsBottom[sliderItem?.id]?.width;

      if(sliderItem?.clientWidth > sliderWidth && hideWidth === undefined) {
        hideElementsBottom[sliderItem?.id] = {width: sliderItem.clientWidth};
        filteredItemsBottom = filteredItemsBottom.filter((item) => +item?.id !== +sliderItem?.id)
      }

      return sliderItem;
    })

    Object.keys(hideElementsBottom).map((id) => {
      if(hideElementsBottom[id]?.width > sliderWidth) {
        hideElementsBottom[id] = {width: hideElementsBottom[id]?.width};
        filteredItemsBottom = filteredItemsBottom.filter((item) => +item?.id !== +id)
        return id;
      }

      if(hideElementsBottom[id]?.width < sliderWidth) {
        const unhideItem = GALLERY_SLIDERS?.bottom.filter((item) => +item?.id === +id)[0];
        filteredItemsBottom.push(unhideItem);

        delete hideElementsBottom[id];
      }

      return id;
    })

    setViewItems({ top: filteredItemsTop, bottom: filteredItemsBottom });
    setHideElements({ top: hideElementsTop, bottom: hideElementsBottom });
  }

  const renderItems = (sliderItems) => {
    return (
      sliderItems?.map((slide, index) => (
        <div
          key={Date.now() + index}
          className={`slide__item pr background__static${forceRender?' preloader':''}`}
          onClick={() => navigateTo(slide)}
          id={slide?.id}
        >
          {!!slide?.isFree && <Badge size="sm" theme="green" text="Free"/>}
          <Icon
            src={`${PRODUCT_URL}/${slide?.pathToBrowse}`}
            alt={slide?.alt}
          />
          {slide?.user && (
            <HoverProductCard
              author={slide?.user}
              inCart={defineItemInCart(slide?.pathToBrowse)}
              addToCart={(e) => addToCart(e, slide)}
              isFree={slide?.isFree}
              getFree={evt => getFree(evt, slide)}
            />
          )}
        </div>
      ))
    )
  }

  const renderSlider = (sliderItems = [], settings, sliderElement, sliderRef) => {
    return (
      <div ref={sliderRef}>
        <Slider ref={sliderElement} {...settings}>
          {renderItems(sliderItems)}
        </Slider>
      </div>
    );
  };

  const showNext = () => {
    sliderElementFirst?.current?.slickNext();
    sliderElementSecond?.current?.slickNext();
  };

  const showPrev = () => {
    sliderElementFirst?.current?.slickPrev();
    sliderElementSecond?.current?.slickPrev();
  };

  return (
    <div className="gallery__container">
      <div className="main__container gallery__content">
        <Text as="h1">Works of our illustrators</Text>
        <Text as="h3">
          Our gallery grows constantly. There are
          <Text as="span" className="gallery__count">
            {totalAmount}
          </Text>
          illustrations now,
          <Text as="span" className="gallery__count">
            {freeAmount}
          </Text>
          of them are free!
        </Text>
      </div>
      {renderSlider(viewItems?.top, settingsTop, sliderElementFirst, sliderTopRef)}
      {renderSlider(viewItems?.bottom, settingsBottom, sliderElementSecond, sliderBottomRef)}
      <div className="d-flex jc-center mt-24 gallary__arows">
        <CarouselArrow onClick={showPrev} size="lg" />
        <CarouselArrow onClick={showNext} size="lg" direction="next" className="ml-40" />
      </div>
    </div>
  );
};

export default Gallery;
