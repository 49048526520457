import React, { useEffect, useState, useRef } from 'react';
import './login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Recapcha, } from '@shared';
import { useForm } from '@shared';
import { Button, Input, Text, Icon } from '@ui-kit';
import { 
  loginTC,
  getServerError, 
  setServerErrorsAC, 
  setModalSignupShowAC,
  setModalLoginShowAC,
  setModalRecoveryShowAC,
} from '@entities';
import { ROLES } from "@entities/user";
import onLoginFormValid from './login-validation.hook';
import onCaptchaValid from '../security-form/captcha-validation.hook';
import { BASE_URL, ADMIN_PROJECT_CREATE_URL, ADMIN_URL, ADMIN_USER_URL } from '@app/routes';
import { LoadingAdmin } from '@shared/ui';
import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg';

//Autofill call onChangeInput form 2 time
const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const serverError = useSelector(getServerError);

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordShow, setPasswordShow] = useState(false);

  const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const eyeIcon = isPasswordShow ? EyeOnIcon : EyeOffIcon;
  const inputType = isPasswordShow ? 'text' : 'password';
  const user = {
    email: '',
    password: '',
  };

  const { formData, onChangeInput, onSubmitForm } = useForm(
    user,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    return () => dispatch(setServerErrorsAC([]));
  }, [dispatch]);

  /*
   * 'function declaration' is necessary to call after describing the hook
   */
  async function handlerBeforeSubmitingForm() {
    // recaptchaRef.current.execute();

    setIsLoading(true);

    await Recapcha.start(recaptchaRef);

    const formErrors = onLoginFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    document.querySelector('.main__wrapper').style.overflow = '';
    setErrors({});
  }

  const onSecuritySuccess = async (token) => {
    formData.captchToken = token;

    recaptchaRef.current.reset();

    const isResult = await dispatch(loginTC(formData));

    if (!isResult) {
      return setIsLoading(false);
    }

    const isUser = isResult?.role === ROLES.USER;

    const redirectUrl = localStorage.getItem('fullpath') || '';
    const isPassConfirm = redirectUrl.includes('account/password/confirm');
    const isAdminUrl = redirectUrl.includes(ADMIN_URL.pathname);
    const isAdminUserUrl = redirectUrl.includes(ADMIN_USER_URL.pathname);
    const isOldUserProject = !!redirectUrl.match(/admin\/projects\/\d+/)?.length;
    
    if (redirectUrl) {
      if(isPassConfirm) {
        navigate(BASE_URL);
        return localStorage.removeItem('fullpath');
      }
      
      if(isAdminUserUrl) {
        if (isUser) {
          navigate(redirectUrl);
          return localStorage.removeItem('fullpath');
        }

        navigate(BASE_URL);
        return localStorage.removeItem('fullpath');
      }

      if(isAdminUrl) {
        if (isUser) {
          if (!isOldUserProject) {
            navigate(BASE_URL);
            return localStorage.removeItem('fullpath');
          }

          navigate(redirectUrl);
          return localStorage.removeItem('fullpath');
        }

        if (isOldUserProject) {
          navigate(BASE_URL);
          return localStorage.removeItem('fullpath');
        }

        navigate(redirectUrl);
        return localStorage.removeItem('fullpath');
      }

      navigate(redirectUrl);
      return localStorage.removeItem('fullpath');
    }

    return navigate(ADMIN_PROJECT_CREATE_URL);
  };

  const onSecurityError = () => {

    if (!captcha) return;

    const captchaError = onCaptchaValid({ captchToken: null });

    setErrors({ ...errors, ...captchaError });
  };

  const onRecaptchaChange = (token) => {
    setCaptcha(token);
    
    if (!token) {
      onSecurityError();
    }

    return  onSecuritySuccess(token);
  };

  const onIconClick = () => {
    if (isPasswordShow) {
      return setPasswordShow(false);
    }

    return setPasswordShow(true);
  };

  const goToSignup = () => {
    dispatch(setModalLoginShowAC(false));
    dispatch(setModalSignupShowAC(true));
  }

  const goToRecovery = () => {
    dispatch(setModalLoginShowAC(false));
    dispatch(setModalRecoveryShowAC(true));
  }

  return (
    <div className={`form__container login__form-container ${isLoading && 'pr'}`}>
      {isLoading && <LoadingAdmin />}
      <div className="form__header">
        <Icon src="\logo.svg" />
        <Text as="h2" className="form__header-title">
          Log in to your account
        </Text>
        <Text as="h5" className="login__header-subtitle">
          Welcome back! Please enter your details.
        </Text>
      </div>
      <div className="form__content">
        <form onSubmit={onSubmitForm} className="login__form">
          <Input
            placeholder="Enter your email"
            name="email"
            type="email"
            autoComplete="email"
            required
            label="Email"
            value={formData?.email || ''}
            onChange={onChangeInput}
            className={errors.email && 'form-item__error'}
          />
          {errors.email && (
            <Text as="span" className="error">
              {errors.email}
            </Text>
          )}
          <Input
            placeholder="Enter your password"
            name="password"
            type={inputType}
            autoComplete="current-password"
            required
            label="Password"
            value={formData?.password || ''}
            onChange={onChangeInput}
            className={errors.password && 'form-item__error'}
            iconRight={eyeIcon}
            onIconClick={() => onIconClick()}
          />
          {errors.password && (
            <Text as="span" className="error">
              {errors.password}
            </Text>
          )}

          <div className="d-flex jc-sb ai-center mt-24">
            {/*<CheckBox
              label="Remember for 30 days"
              className="checkbox__item"
              checked="checked"
          />*/}

            <Button type="button" onClick={goToRecovery} size="md" theme="link-color">
              Forgot password
            </Button>
          </div>

          <Button type="submit" className="mt-24" size="lg">
            <Text as="span">Sign in</Text>
          </Button>

          <Recapcha
            recaptchaRef={recaptchaRef}
            className="g-recaptcha"
            sitekey={SITE_KEY}
            onChange={onRecaptchaChange}
            onErrored={onSecurityError}
            size= "invisible"
            hidden={captcha !== null}
            //badge="inline"
          />

        </form>
        {errors.captcha && (
          <Text as="span" className="error">
            {errors.captcha}
          </Text>
        )}
        {/* server errors block */}
        {!!serverError.length && (
          <div className="server__error">
            {serverError.map((error) => (
              <Text as="span" key={error.param} className="error">
                {error.msg}
              </Text>
            ))}
          </div>
        )}
      </div>
      <div className="login__footer">
        <Text as="span">Don’t have an account? </Text>
        <Button onClick={goToSignup} size="md" theme="link-color">
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
