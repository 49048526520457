import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HoverProductCard, ProductInfo } from '@widgets';
import * as ReactDOMClient from 'react-dom/client';
import { Icon, Badge } from '@ui-kit';
import { clearAllNode } from '@shared';
import { 
  addProductToCartTC, 
  getCart, 
  setModalLoginShowAC, 
  setModalGetFreeProductTC,
  getCustomer, 
  isAuth 
} from '@entities';
import { BROWSE_URL, ADMIN_PROJECTS_URL, ADMIN_USER_PROJECTS_URL } from '@app/routes';
import { ROLES } from '@entities/user';

const { REACT_APP_PRODUCTS_URL } = process.env;

const IllustrationCard = ({ product, maxWidth, onSelectTag, setIsShowInfo, preloader, isSorting }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(getCart);
  const customer = useSelector(getCustomer);
  const isUserAuth = useSelector(isAuth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAddInfoBlock, setAddInfoBlock] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  // eslint-disable-next-line
  const [assetId, setAssetId] = useState();
  const isSortingRef = useRef(isSorting);
  const isLoadedRef = useRef(isLoaded);

  const isProductInCart = cart.find((item) => item?.id === product?.id);
  if (isProductInCart) {
    product.inCart = true;
  };

  const {
    name,
    linkId,
    id,
    pathToBrowse,
    firstName,
    lastName,
    ava,
    correctWidth,
    inCart = false,
    isFree = false,
    user ={},
  } = product;

  const src = `${REACT_APP_PRODUCTS_URL}/${pathToBrowse}`
  const executor = ava ? { firstName, lastName, ava } : user;
  const preloadClass = preloader || !isLoaded ? 'preloader' : '';

  const handleScroll = () => {
    const scrollLoad = window.innerHeight * 2 + window.scrollY;

    if (!isSortingRef.current && !isLoadedRef.current && scrollLoad >= ref.current?.offsetTop) {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    isLoadedRef.current = isLoaded;
  }, [isLoaded]);

  useEffect(() => {
    isSortingRef.current = isSorting;
  }, [isSorting]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isSorting && !isLoaded && !preloader) {
      setTimeout(() => handleScroll(), 200);
    }
    // eslint-disable-next-line
  },[isSorting, preloader])

  useEffect(() => {
    // eslint-disable-next-line
    const assetId = getAssetsId();
    setAssetId(assetId);

    if (assetId === product.id && isAddInfoBlock) {
      updateInfoBlock(assetId)
    }
    // eslint-disable-next-line
  }, [maxWidth, isUserAuth]);

  useEffect(() => {
    // eslint-disable-next-line
    const assetId = getAssetsId();
    setAssetId(assetId);

    if (assetId === product.id) {
      setTimeout(() => showAsset(assetId), 700);
    }
    // eslint-disable-next-line
  }, []);

  const addToCart = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(addProductToCartTC(product, rerenderCart));
    // eslint-disable-next-line
  }, []);

  const getAssetsId = () => {
    const params = Object.fromEntries(searchParams.entries());

    if (params.hasOwnProperty('asset_id')) {
      return +params.asset_id;
    }
  };

  if (!pathToBrowse) return;

  const checkRole = (role) => {
    return customer && customer?.role === role;
  };

  const updateInfoBlock = (assetId) => {
    setAddInfoBlock(false);
    
    setTimeout(() => {
      const lastInRow = document.querySelector(`li[data-last="${assetId}"]`);
      if (!lastInRow) return

      clearAllNode('div[data-info-last]');

      lastInRow.removeAttribute('data-last');
      showAsset(assetId);

      setAddInfoBlock(true);
    }, 1000)

  };

  const showAsset = (assetId) => {
    const assetRef = document.querySelector(`.data-id-${assetId}`);

    if (assetRef) {
      renderInfoBlock({ current: assetRef });
    }

    setIsShowInfo(true);
  };

  const onClick = (e) => {
    e.preventDefault();

    searchParams.set('asset_id', id);
    setSearchParams(searchParams, {replace: true});

    clearAllNode('div[data-info-last]');
    const assetId = getAssetsId();
    setAssetId(assetId);

    renderInfoBlock(ref);
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  const createProject = (product) => {
    if(isUserAuth) {
      if (checkRole(ROLES.USER)) {
        localStorage.setItem('productToNewProject', JSON.stringify({
          id: product.id, 
          title: product.title, 
          pathToBrowse: product.pathToBrowse
        }))
        return window.open(`${ADMIN_USER_PROJECTS_URL.pathname}?create-new=${product?.id}`, '_blank');
      }

      return window.open(ADMIN_PROJECTS_URL.pathname, '_blank');
    }

    return dispatch(setModalLoginShowAC(true));
  };

  const renderInfoBlock = (currentRef) => {
    const neighbour = getLastNeighbour(currentRef);

    if (!neighbour) {
      return;
    };

    const domNode = createInfoBlock(id);
    neighbour.after(domNode);
    const root = ReactDOMClient.createRoot(domNode);

    domNode.scrollIntoView({ behavior: 'smooth' });

    const renderComponentWithRouter = (
      <ProductInfo
        product={product}
        addToCart={addToCart}
        getFree={getFree}
        closeInfo={closeInfo}
        onSelectTag={onSelectTag}
        linkPreview={true}
        navigateTo={navigateTo}
        isGallery={true}
        callback={createProject}
      />
    );

    root.render(renderComponentWithRouter);
  };

  const getLastNeighbour = (currentRef) => {
    const row = currentRef?.current?.attributes['data-row']?.value;
    const id = currentRef?.current?.attributes['data-id']?.value;

    const allInRow = document.querySelectorAll(`li[data-row="${row}"]`);
    const lastToNumber = allInRow.length-1 ;

    const neighbour = allInRow[lastToNumber];
    neighbour.setAttribute('data-last', id);

    return neighbour;
  };

  const createInfoBlock = (dataIndex) => {
    const newNode = document.createElement('div');
    newNode.className = `product__item--info data-info-${id}`;
    newNode.dataset.infoLast = dataIndex;

    return newNode;
  };

  const rerenderCart = () => {
    const infoElement = document.querySelector(`.data-info-${id}`);
    if (infoElement) {
      const btnCountainer = infoElement.querySelector('.info__btn');
      btnCountainer.classList.add('product-in-cart__wrapper');
    }
  };

  const getFree = (event) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(setModalGetFreeProductTC({show: true, product: product}));
  }

  const closeInfo = (id) => {
    const infoElement = document.querySelector(`.data-info-${id}`);
    infoElement.remove();
    searchParams.delete('asset_id');
    setSearchParams(searchParams, {replace: true});
    setIsShowInfo(false);
  };

  return (
    <li
      className={`product__item block data-id-${id} ${preloadClass}`}
      ref={ref}
      onClick={onClick}
      data-width={correctWidth}
      data-id={id}
    >
      <a
        href={`${BROWSE_URL.pathname}/${linkId}-${id}`}
        className="product__link pr background__static"
      >
        {!!isFree && <Badge size="sm" theme="green" text="Free"/>}
        {isLoaded && 
          <Icon
            src={src}
            className="product__img"
            alt={name}
          />
        }

        {user?.firstName && (
          <HoverProductCard
            author={executor}
            addToCart={addToCart}
            getFree={getFree}
            inCart={inCart}
            productId={id}
            isFree={isFree}
          />
        )}
      </a>
    </li>
  );
};

export default IllustrationCard;
