import React, { lazy } from 'react';
import {
  BASE_URL,
  CART_URL,
  ADMIN_URL,
  BROWSE_URL,
  OLD_BROWSE_URL,
  CAREER_URL,
  LICENSES_URL,
  ABOUT_US_URL,
  CHECKOUT_URL,
  CONTACTS_URL,
  NOT_FOUND_URL,
  ADMIN_LOGIN_URL,
  ADMIN_USERS_URL,
  OLD_ADMIN_PROJECTS_URL,
  OLD_ADMIN_USER_PROJECTS_URL,
  BROWSE_ITEM_URL,
  OLD_BROWSE_ITEM_URL,
  RETURN_POLICY_URL,
  COOKIE_POLICY_URL,
  ADMIN_PROJECTS_URL,
  PAYMENT_STATUS_URL,
  PRIVACY_POLICY_URL,
  JOB_APPLICATION_URL,
  OLD_JOB_APPLICATIONS_URL,
  ADMIN_PURCHASES_URL, 
  ADMIN_USER_BILLS_URL,
  TERMS_CONDITIONS_URL,
  CHECKOUT_PROJECT_URL,
  RESET_PASS_REJECT_URL,
  RESET_PASS_CONFIRM_URL,
  VACANCY_THEME_MAKER_URL,
  VACANCY_ILLUSTRATOR_URL,
  VACANCY_SOFTWARE_TESTER_URL,
  VACANCY_PROJECT_MANAGER_URL,
  VACANCY_ATTRIBUTOR_URL,
  ADMIN_USER_SETTINGS_URL,
  ADMIN_USER_PASSWORD_URL,
  ADMIN_USER_PROJECTS_URL,
  ADMIN_PROJECT_CREATE_URL,
  ADMIN_MANAGEMENT_SETTINGS_URL,
  ADMIN_ARCHIVE_PROJECTS_URL,
  PAYMENT_PROJECT_STATUS_URL,
  ACTIVATED_SUCCESS_URL,
  ADMIN_DASHBOARD_URL,
  ADMIN_MY_SALES_URL,
  ADMIN_PRODUCTS_URL,
  ADMIN_PROMOTED_URL,
  ADMIN_FREE_PRODUCTS_URL,
  ADMIN_EDIT_PRODUCT_URL,
  TAROTTALES_PRIVACY_POLICY_URL,
  TAROTTALES_TERMS_CONDITIONS_URL,
  TAROTTALES_LICENSE_SOUND_URL,
} from './routes.constants';

import FooterProvider from '@app/providers/footer';
import { useParams, Navigate } from 'react-router-dom';

const Cart = lazy(() => import('@pages/cart'));
const HomePage = lazy(() => import('@pages/home'));
const Career = lazy(() => import('@pages/career'));
const LoginPage = lazy(() => import('@pages/login'));
const AboutUs = lazy(() => import('@pages/about_us'));
const Users = lazy(() => import('@pages/admin/users'));
const Contacts = lazy(() => import('@pages/contacts'));
const Checkout = lazy(() => import('@pages/checkout'));
const LicensePage = lazy(() => import('@pages/licenses'));
const ProductsPage = lazy(() => import('@pages/products'));
const ProductsItem = lazy(() => import('@pages/products_item'));
const MyBills = lazy(() => import('@pages/admin/my_bills'));
const Projects = lazy(() => import('@pages/admin/projects'));
const Dashboard = lazy(() => import('@pages/admin/products/dashboard'));
const MySales = lazy(() => import('@pages/admin/products/my_sales'));
const ProductsManagement = lazy(() => import('@pages/admin/products'));
const ProductEdit = lazy(() => import('@pages/admin/products/product_edit'));
const PromotedManagement = lazy(() => import('@pages/admin/products/promoted'));
const FreeManagement = lazy(() => import('@pages/admin/products/free'));
const ReturnPolicy = lazy(() => import('@pages/return_policy'));
const CookiePolicy = lazy(() => import('@pages/cookie_policy'));
const PrivacyPolicy = lazy(() => import('@pages/privacy_policy'));
const ResetPassPage = lazy(() => import('@pages/password/reset'));
const AdminSettings = lazy(() => import('@pages/admin/settings'));
const MyPurchases = lazy(() => import('@pages/admin/my_purchases'));
const TermsConditions = lazy(() => import('@pages/terms_conditions'));
const CheckoutProject = lazy(() => import('@pages/checkout_project'));
const ProjectsUser = lazy(() => import('@pages/admin/projects_user'));
const PaymentsStatus = lazy(() => import('@pages/payments_status'));
const PaymentProjectStatus = lazy(() => import('@pages/pay_project_status'));
const CreateProject = lazy(() => import('@pages/admin/create_project'));
const VacancyThemeMaker = lazy(() => import('@pages/vacancies/theme_maker'));
const VacancyIllustrator = lazy(() => import('@pages/vacancies/illustrator'));
const VacancySoftTester = lazy(() => import('@pages/vacancies/software_tester'));
const VacancyProjectManager = lazy(() => import('@pages/vacancies/project_manager'));
const VacancyAttributor = lazy(() => import('@pages/vacancies/attributor'));
const PersonalSettings = lazy(() => import('@pages/admin/personal_settings'));
const PersonalPassword = lazy(() => import('@pages/admin/personal_password'));
const ArchiveProjects = lazy(() => import('@pages/admin/projects_archive'));
const EmailActivated = lazy(() => import('@pages/email_activated'));
const JobApplication = lazy(() => import('@pages/job_application'));
const TarotTalesPrivacyPolicy = lazy(() => import('@pages/tarottales/privacy_policy'));
const TarotTalesTermsAndConditions = lazy(() => import('@pages/tarottales/terms_conditions'));
const TarotTalesLicenseSound = lazy(() => import('@pages/tarottales/license_sound'));

const NotFoundPage = lazy(() => import('@pages/404'));

function OldProjectsAdminRedirect() {
    const { id } = useParams();
    const url = `${ADMIN_ARCHIVE_PROJECTS_URL.pathname}?project_asset_id=${id}&search=${id}`;
    return <Navigate to={ url } />;
}

function OldProjectsUserRedirect() {
    const { id } = useParams();
    const url = `${ADMIN_USER_PROJECTS_URL.pathname}?project_asset_id=${id}`;
    return <Navigate to={ url } />;
}

function OldProductsRedirect() {
    const { linkId } = useParams();
    const url = `${BROWSE_URL.pathname}/${linkId}`;
    return <Navigate to={ url } />;
}

// FOR ALL ANONIMUS
export const STATIC_ROUTES = [
  {
    path: BASE_URL,
    element: (
      <FooterProvider>
        <HomePage />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: BROWSE_URL,
    element: (
      <FooterProvider>
        <ProductsPage />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: OLD_BROWSE_URL,
    element: (
      <Navigate replace to={BROWSE_URL} />
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: BROWSE_ITEM_URL,
    element: (
      <FooterProvider>
        <ProductsItem />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: LICENSES_URL,
    element: (
      <FooterProvider>
        <LicensePage />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ABOUT_US_URL,
    element: (
      <FooterProvider>
        <AboutUs />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: CONTACTS_URL,
    element: (
      <FooterProvider>
        <Contacts />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: CAREER_URL,
    element: (
      <FooterProvider>
        <Career />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: VACANCY_THEME_MAKER_URL,
    element: (
      <FooterProvider>
        <VacancyThemeMaker />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: VACANCY_ILLUSTRATOR_URL,
    element: (
      <FooterProvider>
        <VacancyIllustrator />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: VACANCY_SOFTWARE_TESTER_URL,
    element: (
      <FooterProvider>
        <VacancySoftTester />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: VACANCY_PROJECT_MANAGER_URL,
    element: (
      <FooterProvider>
        <VacancyProjectManager />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: VACANCY_ATTRIBUTOR_URL,
    element: (
      <FooterProvider>
        <VacancyAttributor />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: JOB_APPLICATION_URL,
    element: (
      <FooterProvider>
        <JobApplication />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: OLD_JOB_APPLICATIONS_URL,
    element: (
      <FooterProvider>
        <JobApplication />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: PRIVACY_POLICY_URL,
    element: (
      <FooterProvider>
        <PrivacyPolicy />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: TERMS_CONDITIONS_URL,
    element: (
      <FooterProvider>
        <TermsConditions />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: RETURN_POLICY_URL,
    element: (
      <FooterProvider>
        <ReturnPolicy />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: COOKIE_POLICY_URL,
    element: (
      <FooterProvider>
        <CookiePolicy />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_PROJECT_CREATE_URL,
    element: <LoginPage />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: NOT_FOUND_URL,
    element: (
      <FooterProvider>
        <NotFoundPage />
      </FooterProvider>
    ),
    sitemapIndex: true,
  },
  {
    path: RESET_PASS_CONFIRM_URL,
    element: <ResetPassPage />,
    sitemapIndex: true,
  },
  {
    path: RESET_PASS_REJECT_URL,
    element: <ResetPassPage />, // RejectPassPage
    sitemapIndex: true,
  },
  {
    path: ACTIVATED_SUCCESS_URL,
    element: <EmailActivated />,
    sitemapIndex: true,
  },
  {
    path: TAROTTALES_PRIVACY_POLICY_URL,
    element: (
        <TarotTalesPrivacyPolicy />
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: TAROTTALES_TERMS_CONDITIONS_URL,
    element: (
        <TarotTalesTermsAndConditions />
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: TAROTTALES_LICENSE_SOUND_URL,
    element: (
        <TarotTalesLicenseSound />
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: OLD_BROWSE_ITEM_URL,
    element: (<OldProductsRedirect />),
    sitemapIndex: true,
    exact: false,
  },
];

// FOR AUTH
export const AUTH_ROUTES = [
  {
    path: ADMIN_URL,
    element: <></>,
    sitemapIndex: true,
    internalsRouts: [
      {
        path: ADMIN_LOGIN_URL,
        element: <LoginPage />,
        sitemapIndex: true,
      },
      {
        path: ADMIN_URL,
        element: <NotFoundPage />,
        sitemapIndex: true,
      },
      {
        path: NOT_FOUND_URL,
        element: <NotFoundPage />,
        sitemapIndex: true,
      },
    ],
  },
];


// admin/ FOR ALL AUTH USERS
export const ADMIN_ROUTES = [
  {
    path: ADMIN_PROJECT_CREATE_URL,
    element: <CreateProject />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: CART_URL,
    element: (
      <FooterProvider>
        <Cart />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },

  {
    path: CHECKOUT_URL,
    element: (
      <FooterProvider>
        <Checkout />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: CHECKOUT_PROJECT_URL,
    element: (
      <FooterProvider>
        <CheckoutProject />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: PAYMENT_STATUS_URL,
    element: (
      <FooterProvider>
        <PaymentsStatus />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: PAYMENT_PROJECT_STATUS_URL,
    element: (
      <FooterProvider>
        <PaymentProjectStatus />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_USER_SETTINGS_URL,
    element: (
      <FooterProvider>
        <PersonalSettings />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_USER_PASSWORD_URL,
    element: (
      <FooterProvider>
        <PersonalPassword />
      </FooterProvider>
    ),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_PURCHASES_URL,
    element: (
      <FooterProvider>
        <MyPurchases />
      </FooterProvider>),
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_USER_BILLS_URL,
    element: (
      <FooterProvider>
        <MyBills />
      </FooterProvider>),
    sitemapIndex: true,
    exact: true,
  },
];

// admin/ FOR AUTH EXECUTORS
export const EXECUTOR_ROUTES = [
  {
    path: ADMIN_PROJECTS_URL,
    element: <Projects />,
    sitemapIndex: true,
    exact: true,
  },

  {
    path: ADMIN_ARCHIVE_PROJECTS_URL,
    element: <ArchiveProjects />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: OLD_ADMIN_PROJECTS_URL,
    element: (<OldProjectsAdminRedirect />),
    sitemapIndex: true,
    exact: false,
  },
];

// /admin  FOR AUTH SUPER ADMINS
export const SUPER_ADMIN_ROUTES = [
  {
    path: ADMIN_USERS_URL,
    element: <Users />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_DASHBOARD_URL,
    element: <Dashboard />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_MY_SALES_URL,
    element: <MySales />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_PRODUCTS_URL,
    element: <ProductsManagement />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_EDIT_PRODUCT_URL,
    element: <ProductEdit />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_PROMOTED_URL,
    element: <PromotedManagement />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_FREE_PRODUCTS_URL,
    element: <FreeManagement />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: ADMIN_MANAGEMENT_SETTINGS_URL,
    element: <AdminSettings />,
    sitemapIndex: true,
    exact: true,
  },
];

// admin/ for users
export const USERS_ROUTES = [
  {
    path: ADMIN_USER_PROJECTS_URL,
    element: <ProjectsUser />,
    sitemapIndex: true,
    exact: true,
  },
  {
    path: OLD_ADMIN_USER_PROJECTS_URL,
    element: (<OldProjectsUserRedirect />),
    sitemapIndex: true,
    exact: true,
  },
];
