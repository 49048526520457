import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { CarouselArrow, Icon, Badge } from '@ui-kit';
import { BROWSE_URL } from '@app/routes';
import { HoverProductCard } from '@widgets';
import { setModalGetFreeProductTC } from '@entities';
import './similar-products.scss';

const { REACT_APP_PRODUCTS_URL } = process.env;
const MOBILE_WIDTH = 590;
const RESIZE_DEBOUNCE_DELAY = 100;

const SimilarProducts = ({ items = [], addToCart = () => {} }) => {
  const dispatch = useDispatch();
  const similarRef = useRef(null);
  const resizeTimeoutRef = useRef(null);
  const [lastSlide, setLastSlide] = useState(true);
  const [firstSlide, setFirstSlide] = useState(false);
  const [viewItems, setViewItems] = useState(items);
  const [hideElements, setHideElements] = useState({});

  const getFree = useCallback((event, slide) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setModalGetFreeProductTC({ show: true, product: slide }));
  }, [dispatch]);

  const filterItems = useCallback(() => {
    if (!items.length) return;

    const windowWidth = document.body.clientWidth || 0;
    const sliderWidth = similarRef.current?.getBoundingClientRect().width || 0;
    
    if (!sliderWidth || windowWidth < MOBILE_WIDTH) {
      setViewItems(items);
      setHideElements({});
      return;
    }

    const sliderItems = Array.from(document.querySelectorAll('.similar__item'));
    const newHideElements = { ...hideElements };
    let filteredItems = [...items];

    sliderItems.forEach((sliderItem) => {
      const itemId = sliderItem.id;
      const itemWidth = sliderItem.clientWidth;
      const isHidden = newHideElements[itemId]?.width > sliderWidth;

      if (itemWidth > sliderWidth && !isHidden) {
        newHideElements[itemId] = { width: itemWidth };
      } else if (itemWidth <= sliderWidth && isHidden) {
        delete newHideElements[itemId];
      }
    });

    filteredItems = items.filter(item => !newHideElements[item.id]);
    setViewItems(filteredItems);
    setHideElements(newHideElements);
  }, [items, hideElements]);

  const renderItems = useCallback(() => (
    viewItems.map((slide) => (
      <div
        key={`${slide.id}-${Date.now()}`}
        className="similar__item mr-8 slide__item pr background__static"
        id={slide.id}
      >
        <a
          href={`${BROWSE_URL.pathname}/${slide.linkId}-${slide.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {!!slide.isFree && <Badge size="sm" theme="green" text="Free" />}
          <Icon
            className="similar__img"
            src={`${REACT_APP_PRODUCTS_URL}/${slide.pathToBrowse}`}
            alt="similar_products"
          />

          {slide.user && (
            <HoverProductCard
              inCart={slide.inCart}
              author={slide.user}
              addToCart={(e) => addToCart(e, slide)}
              isFree={slide.isFree}
              getFree={(e) => getFree(e, slide)}
            />
          )}
        </a>
      </div>
    ))
  ), [viewItems, addToCart, getFree]);

  const sliderSettings = {
    infinite: true,
    variableWidth: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <CarouselArrow direction="prev" />,
    nextArrow: <CarouselArrow direction="next" />,
    responsive: [
      {
        breakpoint: 606,
        settings: {
          prevArrow: <CarouselArrow direction="prev" isDisabled={!firstSlide} />,
          nextArrow: <CarouselArrow direction="next" isDisabled={!lastSlide} />,
          afterChange: (currentSlide) => {
            setLastSlide(currentSlide + 1 !== items.length - 1);
            setFirstSlide(!!currentSlide);
          },
        }
      }
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(resizeTimeoutRef.current);
      resizeTimeoutRef.current = setTimeout(filterItems, RESIZE_DEBOUNCE_DELAY);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimeoutRef.current);
    };
  }, [filterItems]);

  useEffect(() => {
    const timer = setTimeout(filterItems, 500);
    return () => clearTimeout(timer);
  }, [items, filterItems]);

  // Условный рендер в конце
  if (!items.length) return null;

  return (
    <div className="similar__container mt-12">
      <div className="similar__slider pr" ref={similarRef}>
        <Slider {...sliderSettings}>
          {renderItems()}
        </Slider>
      </div>
    </div>
  );
};

export default React.memo(SimilarProducts);
