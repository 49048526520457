import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './table-users.scss';

import { Text, Button, IconFeatured } from '@ui-kit';
import UserItem from './UserItem';
import { getUsersTC, getUserTC, getCurrentUser, updateLocalUserTC } from '@entities';
import { FiltersChecked } from "@widgets";
import { ROLES_LIST } from "./item-actions.consts";
import SearchIcon from "@ui-kit/icons/search-accent600.svg";
import SortIcon from "@ui-kit/icons/code-gray500.svg";

const TableUsers = ({users = [], setIsInfoView}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [field, setField] = useState();
  const [order, setOrder] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChoose = (id) => {
    setIsInfoView(true);
    dispatch(getUserTC(id));
    searchParams.set('user_asset_id', id);
    setSearchParams(searchParams, {replace: true});
  };

  const listener = (event) => {
    const data = event?.data;

    if (!data) return;

    const toJson = JSON.parse(data);
    const customEvent = toJson.event;

    if (customEvent === `model.user.afterSave`) {
      const savedUser = toJson?.data;

      dispatch(updateLocalUserTC(Object.assign({}, currentUser, savedUser)));
    }
  };

  useEffect(() => {
    window.socket?.socket.addEventListener("message", listener);

    return () => {
      window.socket?.socket.removeEventListener("message", listener);
    };
    // eslint-disable-next-line
  }, [window.socket]);

  const usersParams = () => {
    const params = window.location.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return {
      ...params,
      page: params.page || 1,
      limit: params.limit || 100,
    };
  };

  const getUsers = () => {
      const params = usersParams();
  
      const options = {
        ...params,
        limit: params.limit || 100,
        page: params.page || 1,
        /*fieldType: params?.fieldType || 'createdAt',
        orderType: params?.orderType || 'DESC',*/
      };
  
      dispatch(getUsersTC(options));
    };

  const onChangeSort = (currentField) => {
    if (currentField !== field) { //
      searchParams.set("fieldType", currentField);
      searchParams.set("orderType", "DESC");
      setSearchParams(searchParams, { replace: true });

      setField(currentField);
      setOrder("DESC");
      return getUsers();
    }

    const params = usersParams();
    let currentOrder = order;

    if (params?.orderType) {
      currentOrder = params.orderType;
    }

    let newOrder = "";
    if (currentOrder === "DESC") {
      newOrder = "ASC";
    } else if (currentOrder === "ASC") {
      newOrder = "DESC";
    } else {
      newOrder = "DESC";
    }

    searchParams.set("orderType", newOrder);
    setSearchParams(searchParams, { replace: true });

    setOrder(newOrder);
    getUsers();
  };

  const renderTable = () => {
    const params = usersParams();
    const searchStr = params?.search ? params?.search.replaceAll('+', ' ').trim() : '';

    if (!users.length && searchStr) {
      return (
        <div className="admin__table-body not-found-message">
          <IconFeatured src={SearchIcon} theme="primary" size="lg" />

          <div className="not-found__content">
            <Text as="h4" className="not-found__title">
              User not found
            </Text>
            <Text as="h6" className="not-found__subtitle">
              Your search "{searchStr}" did not match any users. Please
              try again or try changing the search term.
            </Text>
          </div>
        </div>
      )
    };

    return (
      <div className="admin__table-body">
        {users.map((item, index) => (
          <UserItem
            key={`admin-user-${index}`}
            user={item}
            onClick={onChoose}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="admin__table">
      <div className="admin__table-header">
        <Text as="h6" className="user-name">
          Name
        </Text>
        <Text as="h6" className="user-surname">
          Surname
        </Text>
        <FiltersChecked
          className="user-role"
          filters={ROLES_LIST}
          onChangeFilter={getUsers}
        >
          <Text as="h6">Role</Text>
        </FiltersChecked>
        <Text as="h6" className="user-email">
          Email
        </Text>
        <Button
            onClick={() => onChangeSort("createdAt")}
            size="md"
            theme="link-gray"
            iconRight={SortIcon}
            className="user-create"
          >
            Create
          </Button>
      </div>
      {renderTable()}
    </div>
  );
};

export default TableUsers;
