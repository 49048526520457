export const PROJECTS_FILTERS = [
  {
    title: "Select a state",
    order: "state",
    list: [
      { name: "Brief", value: "1" },
      { name: "Sketching", value: "3" },
      { name: "Illustrating", value: "5" },
      { name: "Completed", value: "8" },
    ],
  },
  {
    title: "Select a status",
    order: "status",
    list: [
      { name: "Sketch is ready", value: "sketchReady" },
      { name: "Illustration is ready", value: "illustrationReady" },
      { name: "Waiting for a response", value: "waitingResponse" },
      // { name: 'Switching to exclusive license', value: 'switchingToExclusive' },
      { name: "Work in progress", value: "workProgress" },
      { name: "Source files are ready", value: "sourceReady" },
    ],
  },
];

export const USERS_FILTERS = [
  {
    title: "Select field",
    order: "searchColumns",
    list: [
      { name: "Name", value: "firstName" },
      { name: "Surname", value: "lastName" },
      { name: "Email", value: "originalEmail" },
    ],
  },
  {
    title: "Role",
    order: "roles",
    list: [
      { name: "User", value: "USER" },
      { name: "Executor - Interviewer", value: "INTERVIEWER" },
      { name: "Executor - Illustrator", value: "ILLUSTRATOR" },
      { name: "Executor - Art Director", value: "ART_DIRECTOR" },
      { name: "Admin", value: "ADMIN" },
    ],
  },
];

export const PRODUCTS_FILTERS = [
  {
    title: "Select a field",
    order: "searchColumns",
    list: [
      { name: "Title", value: "title" },
      { name: "Product Id", value: "id" },
      { name: "Description", value: "html_description" },
      { name: "Tag", value: "key_words" },
    ],
  },
];

export const MY_SALES_FILTERS = [
  {
    title: "Select a field",
    order: "searchColumns",
    list: [
      { name: "Title", value: "title" },
      { name: "Product Id", value: "productId" },
      { name: "Customer name", value: "customerName" },
      { name: "Customer email", value: "customerEmail" },
    ],
  },
];

export const PROMO_FILTERS = [
  { name: "Newest", value: "createdAt" },
  { name: "Popular", value: "popular" },
];
