import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import "./adminPanelWrapper.scss";

import { 
  getSearchPage,
  setIsMobileScreenViewAC,
  getIsMobileScreenView,
} from '@entities';
import { HorizontalTabsAdmin, Footer } from "@widgets";
import { Text, Icon } from "@ui-kit";

const AdminPanelWrapper = ({ 
  children = () => {},
  isContentItemView = false,
  setContentItemView = () => {}
}) => {
  const MIN_WIDTH = 670;
  const searchPage = useSelector(getSearchPage);
  const dispatch = useDispatch();

  const isMobileScreenView = useSelector(getIsMobileScreenView);
  const [isSmallWidth, setIsSmallWidth] = useState(
    window.innerWidth <= MIN_WIDTH
  );
  const [searchParams] = useSearchParams();

  let wrapperStyles = "admin-content d-flex jc-sb";
  const mobileStyles = ["mobile-admin-wrapper"];
  const isHideTabs = isContentItemView && isSmallWidth;

  if (isContentItemView && isSmallWidth) {
    mobileStyles.push("content-open");
  }

  if (isSmallWidth) {
    wrapperStyles = mobileStyles.join(" ");
  }

  useEffect(() => {
    const handleResize = (event) => {
      const eventWidth = event.target.innerWidth;
      if (eventWidth <= MIN_WIDTH) {
        setIsSmallWidth(true);
      } else {
        setIsSmallWidth(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const project_asset_id = searchParams.get("project_asset_id");
    if (project_asset_id && isMobileScreenView) {
      setContentItemView(true);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    const header = document.querySelector("header");
    const main = document.querySelector(".main__content");

    if (isSmallWidth) {
      dispatch(setIsMobileScreenViewAC(true));
    }

    if (isContentItemView && isSmallWidth && searchPage === "users") {
      header.style.display = "none";
      main.style.padding = "0";
    } else {
      header.style.display = "";
      main.style.padding = "";
    }
    // eslint-disable-next-line
  }, [isContentItemView, isSmallWidth]);

  const renderAdminPanel = () => {
    if (isSmallWidth && searchPage === "users") {
      return (
        <>
            {!isHideTabs && <HorizontalTabsAdmin />}
            <div className={wrapperStyles}>
              {children}
            </div>
        </>
      );
    }

    if (isSmallWidth && searchPage !== "users") {
      return (
        <>
          <div className="mobile-user-panel">
            <div className="mobile-user-panel__header">
              <div className="title">Mobile version</div>
              <Text as="h4" className="sub-title">
                Our techies are currently working on the mobile version of the
                chat and it will soon be ready to use. Meanwhile, we are eager
                to cooperate with you on tablet. Turn your phone horizontally to
                switch to the tablet version.
              </Text>
            </div>
            <div className="mobile-user-panel__banner">
              <Icon src="/images/banner-mobile-user-panel.jpg" />
            </div>
          </div>
          <Footer />
        </>
      );
    }

    return (
      <>
        <HorizontalTabsAdmin />
        <div className="d-flex jc-sb admin-wrapper">{children}</div>
      </>
    );
  };

  return (
    <div className="admin d-flex">
      <div className="w-100">{renderAdminPanel()}</div>
    </div>
  );
};

export default AdminPanelWrapper;
