import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import "./panel-user-info.scss";

import { useForm, geShortCut } from "@shared";
import profileFormValid from "../profileSettings/profile-validation.hook";
import {
  getCurrentUser,
  updateUserTC,
  setAlertAC,
  updateUserAvaTC,
  Snackbar,
} from "@entities";
import {
  FileUpload,
  ProfileAddress,
  ProfileRole,
  ProfileNotifications,
} from "@widgets";
import { Button, Input, Avatar } from "@ui-kit";
import EmailIcon from "@ui-kit/icons/mail-gray500.svg";
import BackIcon from "@ui-kit/icons/chevron-accent700.svg";

const PanelUserInfo = ({ setModalDeleteUser, isUsersEmpty = false, setIsInfoView }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMessagesSend } = currentUser;

  const { formData, onChangeInput, onSubmitForm, setFormData } = useForm(
    currentUser,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    if (!currentUser?.id || isUsersEmpty) {
      return setFormData({});
    }

    setFormData(currentUser);

    return () => setFormData({});
    // eslint-disable-next-line
  }, [currentUser, isUsersEmpty]);

  const [errors, setErrors] = useState({});

  /*if (!currentUser?.id) {
    return;
  }*/

  async function handlerBeforeSubmitingForm() {
    const formErrors = profileFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    dispatch(updateUserTC(currentUser.id, formData));

    setErrors({});
    // return dispatch(getUsersTC(window.location.search));
  }

  const deleteUser = () => {
    setModalDeleteUser({ status: true, userId: currentUser.id });
  };

  const resetForm = () => {
    setFormData(currentUser);
    setErrors({});
  };

  const backInUsers = () => {
    /*if (userIsNotSaved) {
      return dispatch(setIsUnsavedUserShowAC(true));
    }*/

    searchParams.delete("user_asset_id");
    setSearchParams(searchParams, { replace: true });

    setIsInfoView(false);
  };

  const renderAvatar = () => {
    if (formData.ava && formData.ava?.slice(0, 4) === "blob") {
      return (
        <Avatar
          srcStatic={formData.ava}
          className="xxl"
          text={geShortCut(formData.firstName, formData?.lastName)}
        />
      );
    }
    return (
      <Avatar
        src={formData.ava}
        className="xxl"
        text={geShortCut(formData.firstName, formData?.lastName)}
      />
    );
  };

  const onAvatarUpload = (_user) => {
    return async (files) => {
      const uploadPromise = dispatch(updateUserAvaTC(files, _user));
      const uploadedAvatarName = (await uploadPromise)?.data.avatarName;
      formData.ava = uploadedAvatarName;

      onChangeInput({ target: { name: "ava", value: uploadedAvatarName } });
    };
  };

  const setAvatarErrors = async (errors) => {
    const snackbar = new Snackbar({
      status: 500,
      message: (
        <div className="">
          <h5 className="snack__header">{errors.uploadImage}</h5>
        </div>
      ),
    });

    dispatch(setAlertAC(snackbar));
  };

  return (
    <div className="pr panel__user-info">
      <div className="user-info__header">
        <div className="user-info__back-block">
          <Button
            size="md"
            theme="tertiary-gray"
            iconLeft={BackIcon}
            onClick={backInUsers}
          />
        </div>
        <div className="admin-user-info__buttons-block">
          <Button
            size="md"
            type="button"
            theme="tertiary-gray"
            onClick={resetForm}
          >
            Reset
          </Button>
          <Button size="md" onClick={onSubmitForm}>
            Save changes
          </Button>
        </div>
      </div>
      <div className="admin-user-info__wrapper">
        <form id="admin-user-info" className="admin-user-info__settings-form">
          <div className="admin-user-info__inputs-block divider mb-43">
            <Input
              required
              type="text"
              name="firstName"
              label="First Name"
              onChange={onChangeInput}
              value={formData?.firstName || ""}
              placeholder="Enter your first name"
              className={errors.firstName && "form-item__error"}
            />
            <Input
              type="text"
              name="lastName"
              label="Last Name"
              onChange={onChangeInput}
              value={formData?.lastName || ""}
              placeholder="Enter your last name"
              className={errors.lastName && "form-item__error"}
            />
          </div>
          <div className="admin-user-info__input divider">
            <Input
              required
              name="email"
              type="email"
              label="Email"
              iconLeft={EmailIcon}
              onChange={onChangeInput}
              value={formData?.email || ""}
              placeholder="Enter your email"
              className={errors.email && "form-item__error"}
            />
          </div>

          <div className="admin-user-info__avatar-block divider mb-43">
            <label>Photo</label>
            <div className="avatar-uploader d-flex">
              {renderAvatar()}
              <FileUpload
                key={currentUser.id}
                text={geShortCut(currentUser.firstName, currentUser?.lastName)}
                onFileUpload={onAvatarUpload(currentUser)}
                setErrors={setAvatarErrors}
              />
            </div>
          </div>

          <div className="admin-user-info__address-container divider mb-20">
            <ProfileAddress
              errors={errors}
              user={currentUser}
              formData={formData}
              onChangeInput={onChangeInput}
            />
          </div>

          <div className="admin-user-info__role-container divider mb-20">
            <ProfileRole
              errors={errors}
              user={currentUser}
              formData={formData}
              onChangeInput={onChangeInput}
            />
          </div>

          <ProfileNotifications
            onChangeInput={onChangeInput}
            isMessagesSend={isMessagesSend}
          />
        </form>
        <div className="admin-user-info__delete">
          <Button
            type="button"
            size="lg"
            theme="secondary-danger"
            onClick={deleteUser}
          >
            Delete user
          </Button>
        </div>
      </div>
      <div className="admin-user-info__buttons-block">
        <Button
          size="md"
          type="button"
          theme="tertiary-gray"
          onClick={resetForm}
        >
          Reset
        </Button>
        <Button size="md" onClick={onSubmitForm}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default PanelUserInfo;
